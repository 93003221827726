.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-brand-cursor-pointer {
  cursor: pointer;
}

.cursor-pointer {
  overflow: hidden;
  cursor: pointer !important;
}

.cursor-pointer img{
  transition: 300ms ease-in-out;
}

@media only screen and (max-width: 768px) {
    .carousel-item  img {
    max-height: 50vh !important;
  }
  h3 {
    font-size: 2rem !important;
  }
  section.product-hero {
    height: 50vh !important;
  }
}
@media only screen and (max-width: 1200px) {
    .carousel-item  img {
    max-height: 70vh !important;
  }

  section.product-hero {
    height: 60vh !important;
  }
}
@media only screen and (max-width: 600px) {
    .carousel-item  img {
    max-height: 30vh !important;
  }
  section.product-hero {
    height: 40vh !important;
  }
}
/* .cursor-pointer:hover {
  transform: scale(1.05);
  z-index: 10;
} */

.cursor-pointer:hover img{
  transform: scale(1.1);
}

.active-nav-link {
  color: #ffffff !important;
  background-color: #ae2c2b !important;
}

.dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}

/* .active-nav-link.active-underline {
  background-color: #ae2c2b !important;
  border-bottom:2px solid #ae2c2b !important;
  
} */

.btn-danger {
  background-color: #ae2c2b !important;
  border-color:  #ae2c2b !important;
}

.text-danger {
  color: #ae2c2b !important;
}

.btn-outline-danger {
  color: #ae2c2b !important;
  border-color:  #ae2c2b !important;
}

.btn-outline-danger:hover {
  background-color: #ae2c2b !important;
  color: white !important;
  border-color:  #ae2c2b !important;
}

.border-danger {
  border-color:  #ae2c2b !important;
}

.dropdown-toggle::after {
  display: none !important; 
}

